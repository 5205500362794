import { motion, useAnimation } from 'framer-motion'
import { ChevronRight, Pause, Play } from 'lucide-react'
import React, { useEffect, useState } from 'react'

const newsItems = [
  {
    text: 'Admissions Open! Join our vibrant junior school family. Limited seats available for the upcoming term.',
    link: '/admissions',
  },
  {
    text: 'New Adventure: Discover the joy of horse riding in our specially designed junior equestrian program!',
    link: '#horse-riding',
  },
  {
    text: 'Aim High: Archery competitions for all grades. Develop focus and precision!',
    link: '#archery',
  },
  {
    text: ' Visit us to explore our facilities and meet our dedicated teachers.',
    link: '#open-day',
  },
]

const News = () => {
  const [isPaused, setIsPaused] = useState(false)
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0)
  const controls = useAnimation()

  const animationDuration = 15

  useEffect(() => {
    if (!isPaused) {
      controls.start({
        x: '-100%',
        transition: {
          duration: animationDuration,
          ease: 'linear',
          repeat: Infinity,
        },
      })
    } else {
      controls.stop()
    }
  }, [isPaused, controls])

  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        setCurrentNewsIndex((prevIndex) => (prevIndex + 1) % newsItems.length)
      }, animationDuration * 500)
      return () => clearInterval(interval)
    }
  }, [isPaused])

  const handleTogglePause = () => {
    setIsPaused(!isPaused)
  }

  return (
    <div className='w-full bg-gradient-to-r from-blue-100 shadow-md'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
        <div className='h-14 flex items-center justify-between overflow-hidden'>
          <div className='flex-1 overflow-hidden'>
            <motion.div
              animate={controls}
              className='whitespace-nowrap flex items-center'
            >
              {newsItems.map((item, index) => (
                <a
                  key={index}
                  href={item.link}
                  className='inline-flex items-center mr-16 text-blue-800 hover:text-green-700 transition-colors duration-300'
                >
                  <span className='text-base font-medium'>{item.text}</span>
                  <ChevronRight className='ml-2 w-5 h-5' />
                </a>
              ))}
            </motion.div>
          </div>
          <div className='ml-4 flex-shrink-0'>
            <button
              onClick={handleTogglePause}
              className='p-2 text-blue-600 hover:text-green-600 focus:outline-none transition-colors duration-300'
              aria-label={isPaused ? 'Play news ticker' : 'Pause news ticker'}
            >
              {isPaused ? (
                <Play className='w-6 h-6' />
              ) : (
                <Pause className='w-6 h-6' />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default News
