import { motion } from 'framer-motion'
import React from 'react'
import { Link } from 'react-router-dom'

const Rectors = () => {
  return (
    <div className="flex justify-center items-center h-[30vh] w-full relative bg-[#030533] bg-[url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'250\' height=\'250\' viewBox=\'0 0 20 20\'%3E%3Cg %3E%3Cpolygon fill=\'%23091C58\' points=\'20 10 10 0 0 0 20 20\'/%3E%3Cpolygon fill=\'%23091C58\' points=\'0 10 0 20 10 20\'/%3E%3C/g%3E%3C/svg%3E')]">
      <div className='absolute flex h-full w-full'>
        <div className='p-4 text-white flex justify-center items-center w-full'>
          <motion.img
            src='https://github.com/furqanistic/ogs-client/blob/main/client/src/img/img.png?raw=true'
            alt='Rector'
            className='w-[150px] object-contain'
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          />
          <motion.div
            className='flex flex-col justify-center m-4 ml-8'
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <p className='text-2xl tracking-[5px] md:text-xl'>Rector</p>
            <h1 className='text-4xl font-semibold md:text-3xl'>
              Prof. Waqas Zaheer
            </h1>
            <Link to='rector-message' className='text-white no-underline'>
              <motion.a
                className='text-xs underline cursor-pointer'
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Read More
              </motion.a>
            </Link>
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default Rectors
