import { Mail, MapPin, Phone, Send } from 'lucide-react'
import React from 'react'

const NewsLetter = () => {
  return (
    <div className='bg-gradient-to-br from-blue-50 to-blue-100 min-h-screen py-16 px-4 sm:px-6 lg:px-8'>
      <div className='max-w-6xl mx-auto bg-white rounded-2xl shadow-2xl overflow-hidden'>
        <div className='grid grid-cols-1 lg:grid-cols-2'>
          {/* Left Column: Contact Form */}
          <div className='p-8 lg:p-12'>
            <h2 className='text-3xl font-bold text-[#0b0a45] mb-6'>
              Stay Connected
            </h2>
            <p className='text-gray-600 mb-8'>
              Join our newsletter for the latest updates and exclusive content.
            </p>
            <form className='space-y-4'>
              <input
                type='text'
                placeholder='Your Name'
                className='w-full px-4 py-3 rounded-lg bg-gray-100 border-transparent focus:border-blue-500 focus:bg-white focus:ring-0'
              />
              <input
                type='email'
                placeholder='Your Email'
                className='w-full px-4 py-3 rounded-lg bg-gray-100 border-transparent focus:border-blue-500 focus:bg-white focus:ring-0'
              />
              <textarea
                placeholder='Your Message (optional)'
                rows={4}
                className='w-full px-4 py-3 rounded-lg bg-gray-100 border-transparent focus:border-blue-500 focus:bg-white focus:ring-0'
              ></textarea>
              <button
                type='submit'
                className='w-full bg-[#0b0a45] text-white font-semibold py-3 px-4 rounded-lg hover:bg-blue-700 transition duration-300 ease-in-out flex items-center justify-center'
              >
                Subscribe <Send size={18} className='ml-2' />
              </button>
            </form>
          </div>

          {/* Right Column: Map and Contact Info */}
          <div className='bg-[#0b0a45] text-white p-8 lg:p-12 flex flex-col justify-between'>
            <div>
              <h3 className='text-2xl font-semibold mb-6'>Find Us</h3>
              <div className='mb-8 h-64 rounded-lg overflow-hidden'>
                <iframe
                  title='Orion School Location'
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3379.914461877003!2d72.72557447573176!3d32.09859741834679!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39219d002305e299%3A0x61a357121fdeaab6!2sThe%20Orion%20School!5e0!3m2!1sen!2s!4v1727916680998!5m2!1sen!2s'
                  width='100%'
                  height='100%'
                  style={{ border: 0 }}
                  allowFullScreen=''
                  loading='lazy'
                  referrerPolicy='no-referrer-when-downgrade'
                ></iframe>
              </div>
            </div>
            <div className='space-y-4'>
              <div className='flex items-center'>
                <MapPin className='mr-3' size={20} />
                <p>3PXH+C7, near Canal Palm Sargodha, Punjab, Pakistan</p>
              </div>
              <div className='flex items-center'>
                <Phone className='mr-3' size={20} />
                <p>+923077879977</p>
              </div>
              <div className='flex items-center'>
                <Mail className='mr-3' size={20} />
                <p>help@theorionschool.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewsLetter
