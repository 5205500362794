import { AnimatePresence, motion } from 'framer-motion'
import { BookOpen, Briefcase, Info, Menu, Phone, Users, X } from 'lucide-react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const MenuItem = ({ to, children, icon: Icon }) => (
  <Link
    to={to}
    className='flex items-center px-4 py-2 text-white hover:bg-blue-700 rounded-md transition-colors duration-300'
  >
    <Icon className='mr-2 h-5 w-5' />
    <span>{children}</span>
  </Link>
)

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <nav className='bg-[#0b0a45] shadow-lg'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
        <div className='flex items-center justify-between h-20'>
          <Link to='/' className='flex-shrink-0'>
            <div className='flex items-center'>
              <img
                src='https://github.com/furqanistic/ogs-client/blob/website/faculty/src/img/logo-white.png?raw=true'
                alt='The Orion School Logo'
                className='h-10 w-10 mr-3 object-contain'
              />
              <span className='text-white font-bold text-lg sm:text-xl md:text-2xl truncate'>
                THE ORION SCHOOL
              </span>
            </div>
          </Link>
          <div className='hidden md:block'>
            <div className='ml-10 flex items-center space-x-4'>
              <MenuItem to='/' icon={BookOpen}>
                Home
              </MenuItem>
              <MenuItem to='/admissions' icon={Users}>
                Admissions
              </MenuItem>
              <MenuItem to='/contact' icon={Phone}>
                Contact
              </MenuItem>
              <MenuItem to='/portals' icon={Briefcase}>
                Portals
              </MenuItem>
              <MenuItem to='/about' icon={Info}>
                About
              </MenuItem>
            </div>
          </div>
          <div className='md:hidden'>
            <button
              onClick={() => setIsOpen(!isOpen)}
              className='inline-flex items-center justify-center p-2 rounded-md text-white hover:text-yellow-400 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
            >
              {isOpen ? (
                <X className='h-6 w-6' />
              ) : (
                <Menu className='h-6 w-6' />
              )}
            </button>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className='md:hidden bg-blue-900'
          >
            <div className='px-2 pt-2 pb-3 space-y-1 sm:px-3'>
              <MenuItem to='/' icon={BookOpen}>
                Home
              </MenuItem>
              <MenuItem to='/admissions' icon={Users}>
                Admissions
              </MenuItem>
              <MenuItem to='/contact' icon={Phone}>
                Contact
              </MenuItem>
              <MenuItem to='/portals' icon={Briefcase}>
                Portals
              </MenuItem>
              <MenuItem to='/about' icon={Info}>
                About
              </MenuItem>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  )
}

export default Navbar
