import { motion } from 'framer-motion'
import { Facebook, Instagram, Mail, MapPin, Phone, Twitter } from 'lucide-react'
import React from 'react'

const FooterColumn = ({ title, links }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className='flex flex-col items-center md:items-start'
  >
    <h3 className='text-xl font-semibold text-white mb-4'>{title}</h3>
    <ul className='space-y-2'>
      {links.map((link, index) => (
        <motion.li
          key={index}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <a
            href={link.href}
            className='text-gray-300 hover:text-white transition-colors duration-200 flex items-center'
          >
            {link.icon && <link.icon size={16} className='mr-2' />}
            {link.text}
          </a>
        </motion.li>
      ))}
    </ul>
  </motion.div>
)

const Footer = () => {
  const footerSections = [
    {
      title: 'About Us',
      links: [
        { text: 'Aim', href: '#' },
        { text: 'Vision', href: '#' },
        { text: 'Why Us?', href: '#' },
      ],
    },
    {
      title: 'Services',
      links: [
        { text: 'Jobs', href: '#' },
        { text: 'Admissions', href: '#' },
        { text: 'Teaching', href: '#' },
      ],
    },
    {
      title: 'Contact Us',
      links: [
        { text: '0307 787 9977', href: 'tel:+923077879988', icon: Phone },
        { text: '0331 330 9977', href: 'tel:+923355854455', icon: Phone },
        { text: 'Sargodha, Pakistan', href: '#', icon: MapPin },
        {
          text: 'help@orionedu.net',
          href: 'mailto:help@orionedu.net',
          icon: Mail,
        },
      ],
    },
    {
      title: 'Social Media',
      links: [
        { text: 'Facebook', href: '#', icon: Facebook },
        { text: 'Instagram', href: '#', icon: Instagram },
        { text: 'Twitter', href: '#', icon: Twitter },
      ],
    },
  ]

  return (
    <footer className='bg-[#040018] text-white'>
      <div className='max-w-6xl mx-auto px-4 py-12'>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8'>
          {footerSections.map((section, index) => (
            <FooterColumn
              key={index}
              title={section.title}
              links={section.links}
            />
          ))}
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className='mt-12 pt-8 border-t border-gray-700 text-center text-gray-400'
        >
          <p>&copy; 2024 Orion Education. All rights reserved.</p>
        </motion.div>
      </div>
    </footer>
  )
}

export default Footer
