import { motion } from 'framer-motion'
import { PhoneCall } from 'lucide-react'
import React from 'react'

const ContactData = () => {
  return (
    <div className='font-sans'>
      <div className='bg-white text-[#0b0a45] py-3 text-center'>
        <motion.h2
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className='text-xl font-semibold'
        >
          Get in Touch 🤙
        </motion.h2>
      </div>

      <div className='relative h-[60vh] w-full bg-[#0b0a45] overflow-hidden'>
        <div
          className='absolute inset-0 bg-cover bg-center bg-no-repeat'
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cpolygon fill='%231d2acc' points='957 450 539 900 1396 900'/%3E%3Cpolygon fill='%232c23aa' points='957 450 872.9 900 1396 900'/%3E%3Cpolygon fill='%230062f2' points='-60 900 398 662 816 900'/%3E%3Cpolygon fill='%230051ca' points='337 900 398 662 816 900'/%3E%3Cpolygon fill='%23007ee4' points='1203 546 1552 900 876 900'/%3E%3Cpolygon fill='%230068bd' points='1203 546 1552 900 1162 900'/%3E%3Cpolygon fill='%230091b1' points='641 695 886 900 367 900'/%3E%3Cpolygon fill='%23007691' points='587 900 641 695 886 900'/%3E%3Cpolygon fill='%2300a070' points='1710 900 1401 632 1096 900'/%3E%3Cpolygon fill='%23008158' points='1710 900 1401 632 1365 900'/%3E%3Cpolygon fill='%2316aa34' points='1210 900 971 687 725 900'/%3E%3Cpolygon fill='%230f8822' points='943 900 1210 900 971 687'/%3E%3C/svg%3E")`,
            backgroundAttachment: 'fixed',
          }}
        />

        <div className='absolute inset-0 flex flex-col items-center justify-center text-white p-4'>
          <motion.h1
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className='text-4xl md:text-5xl lg:text-6xl font-bold mb-6 text-center'
          >
            The Orion School
          </motion.h1>

          <motion.a
            href='tel:+923077879977'
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className='bg-white text-[#0b0a45] py-3 px-6 rounded-full font-semibold flex items-center justify-center mb-6 transform transition-all duration-300 hover:shadow-lg'
          >
            <span>Click here to speak with us</span>
            <PhoneCall className='ml-2 h-5 w-5' />
          </motion.a>

          <motion.p
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.6 }}
            className='text-xl md:text-2xl font-light text-center'
          >
            Making you proud is our vision!
          </motion.p>
        </div>
      </div>
    </div>
  )
}

export default ContactData
