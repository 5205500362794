import { AnimatePresence, motion } from 'framer-motion'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import React, { useEffect, useState } from 'react'

const slides = [
  {
    image:
      'https://github.com/furqanistic/ogs-client/blob/main/client/src/img/sc-whitee.jpg?raw=true',
    message: 'Empowering Minds, Shaping Futures',
    description: 'JOIN OUR COMMUNITY OF INNOVATORS AND LEADERS',
  },
  {
    image:
      'https://images.pexels.com/photos/296301/pexels-photo-296301.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    message: 'Discover Your Potential',
    description: 'WE ARE DETERMINED TO ACHIEVE THE HIGHEST STANDARDS',
  },
  {
    image:
      'https://images.pexels.com/photos/8535230/pexels-photo-8535230.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    message: 'Excellence in Education',
    description: 'WE TAUGHT HOW TO THINK, NOT WHAT TO THINK',
  },
  {
    image:
      'https://images.pexels.com/photos/15149189/pexels-photo-15149189/free-photo-of-children-painting-on-lawn.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    message: 'Building Tomorrows Leaders',
    description: 'Innovative programs designed for the future',
  },
]

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [direction, setDirection] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide()
    }, 5000)
    return () => clearInterval(timer)
  }, [currentIndex])

  const nextSlide = () => {
    setDirection(1)
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length)
  }

  const prevSlide = () => {
    setDirection(-1)
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + slides.length) % slides.length
    )
  }

  const goToSlide = (index) => {
    setDirection(index > currentIndex ? 1 : -1)
    setCurrentIndex(index)
  }

  const slideVariants = {
    enter: (direction) => ({
      x: direction > 0 ? '100%' : '-100%',
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      x: direction < 0 ? '100%' : '-100%',
      opacity: 0,
    }),
  }

  return (
    <div className='relative w-full h-screen overflow-hidden bg-[#0b0a45]'>
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={currentIndex}
          custom={direction}
          variants={slideVariants}
          initial='enter'
          animate='center'
          exit='exit'
          transition={{ type: 'tween', duration: 0.5 }}
          className='absolute inset-0'
        >
          <img
            src={slides[currentIndex].image}
            alt={`Slide ${currentIndex + 1}`}
            className='w-full h-full object-cover'
          />
          <div className='absolute inset-0 bg-gradient-to-r from-[#0b0a45] via-[#0b0a45]/70 to-transparent flex flex-col items-start justify-center px-16 lg:px-24'>
            <motion.h2
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              className='text-white text-5xl lg:text-7xl font-bold mb-4'
            >
              {slides[currentIndex].message}
            </motion.h2>
            <motion.p
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.4, duration: 0.5 }}
              className='text-white text-xl lg:text-2xl max-w-2xl'
            >
              {slides[currentIndex].description}
            </motion.p>
          </div>
        </motion.div>
      </AnimatePresence>

      <button
        onClick={prevSlide}
        className='absolute left-4 top-1/2 transform -translate-y-1/2 bg-white/10 hover:bg-white/20 p-3 rounded-full text-white transition-all duration-300'
      >
        <ChevronLeft size={32} />
      </button>
      <button
        onClick={nextSlide}
        className='absolute right-4 top-1/2 transform -translate-y-1/2 bg-white/10 hover:bg-white/20 p-3 rounded-full text-white transition-all duration-300'
      >
        <ChevronRight size={32} />
      </button>

      <div className='absolute bottom-8 left-1/2 transform -translate-x-1/2 flex space-x-3'>
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`w-3 h-3 rounded-full transition-all duration-300 ${
              index === currentIndex
                ? 'bg-white scale-125'
                : 'bg-white/50 hover:bg-white/75'
            }`}
          />
        ))}
      </div>
    </div>
  )
}

export default Slider
